<script>
import auditController from '@/auditController'
import Search from '@/pages/search/components/search-v5'
import { GlobalSearch } from '@/pages/search/controllers'

export default {
  extends: Search,
  activated () {
    this.$store.commit('setModule', {
      name: ''
    })
  },
  computed: {
    scopeModes () {
      return [GlobalSearch.GLOBAL_MODE]
    }
  },
  data () {
    return {
      auditController
    }
  },
  watch: {
    '$route.params.id': {
      handler (v) {
        if (v && (v === 'escalation-protocols' || v === 'business-divisions')) {
          this.search.setDocumentType(v).execute(true)
        }
      },
      immediate: true
    }
  }
}
</script>
